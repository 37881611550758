import React from "react";
import GoogleMaps from "simple-react-google-maps";
// import Header2 from "../Common/Header2";
import Header2 from '../Common/Header3';
import Footer from "../Common/Footer";
import Banner from "./../Segments/Banner";
import "../../assets/css/AboutUs/style.css";
import ClientLogo from "../Segments/ClientsLogo";

var bnrimg = require("./../../images/banner/6.jpg");

class ContactUs2 extends React.Component {
  render() {
    return (
      <>
        <Header2 />
        <div className="page-content">
          <Banner
            title="Inspired design for people"
            pagename="Contact us"
            bgimage={bnrimg.default}
          />
          {/* <div class="page-header-area">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="page-header-content">
              <h4 class="title" data-aos="fade-down" data-aos-duration="1200">Contact</h4>
              <nav class="breadcrumb-area" data-aos="fade-down" data-aos-duration="1000">
                <ul class="breadcrumb">
                  <li><a href="index.html">Home</a></li>
                  <li class="breadcrumb-sep">-</li>
                  <li>Contact</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div> */}
          <main class="main-content">
            {" "}
            <section class="contact-area">
              <div class="container">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="contact-info-1">
                      <div class="info-item">
                        <div class="info">
                          <h4 class="title">Address</h4>
                          <p>
                            Your current address goes to here,120 example,
                            country.
                          </p>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="info">
                          <h4 class="title">Phone</h4>
                          <a href="tel:+8801234 567 890">+8801234 567 890</a>
                          <a href="tel:+8801234 567 890">+8801234 567 890</a>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="info">
                          <h4 class="title">Web</h4>
                          <a href="mailto:info@example.com">info@example.com</a>
                          <a href="mailto:www.example.com">www.example.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="contact-form">
                      <h4 class="contact-form-title">Contact Form Title</h4>
                      <p class="desc">Contact Form Brief</p>
                      <form id="contact-form" method="post">
                        <div class="row row-gutter-20">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                type="text"
                                name="con_name"
                                placeholder="Name"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                type="email"
                                name="con_email"
                                placeholder="Email"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                type="text"
                                name="con_phone"
                                placeholder="Number"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input
                                class="form-control"
                                type="text"
                                name="con_name"
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                name="con_message"
                                placeholder="Message"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <button class="btn btn-theme" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="form-message"></div>
                  </div>
                </div>
              </div>
            </section>
          </main>

          {/* <ClientLogo /> */}
        </div>

        {/* <Footer /> */}
      </>
    );
  }
}

export default ContactUs2;

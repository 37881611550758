import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home4 from './Pages/Home4';

import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs2';

import NewsGrid from './Pages/NewsGrid';
import NewsListing from './Pages/NewsListing';
import NewsMasonary from './Pages/NewsMasonary';

import WorkGrid from './Pages/WorkGrid';
import WorkMasonary from './Pages/WorkMasonary';
import WorkCarousel from './Pages/WorkCarousel';
import ProjectDetail from './Pages/ProjectDetail';


class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                        <Switch>
                            <Route path='/' exact component={Home4} />

                            <Route path='/aboutus' exact component={AboutUs} />
                            <Route path='/contactus' exact component={ContactUs} />

                            <Route path='/news-grid' exact component={NewsGrid} />
                            <Route path='/news-listing' exact component={NewsListing} />
                            <Route path='/news-masonary' exact component={NewsMasonary} />

                            <Route path='/work-grid' exact component={WorkGrid} />
                            <Route path='/work-masonary' exact component={WorkMasonary} />
                            <Route path='/work-carousel' exact component={WorkCarousel} />
                            <Route path='/project-detail' exact component={ProjectDetail} />

                         

                    
                            
                            <Route component={Error} />
                            
                            <Route component={Error} />
                        </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;
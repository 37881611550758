import React from "react";
import "../../assets/css/AboutUs/style.css";
import Testimonials from "./Testimonials";

class About3 extends React.Component {
  render() {
    return (
      <div className="m-t40 m-b40">
        <section class="about-area about-inner-area">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="about-inner-wrap">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="about-content">
                        <div class="section-title mb--0">
                          <h2 class="sub-title">Provide the best</h2>
                          <h2 class="title">Furniture For You</h2>
                          <div class="desc">
                            <p>
                              Modern furniture refers to furniture produced from
                              the late 19th century through the present that is
                              influenced by modernism. Post-World War II ideals
                              of cutting excess, commodification, and
                              practicality of materials in design heavily
                              influenced the aesthetic of the furniture.
                            </p>
                            <p>
                              It was a tremendous departure from all furniture
                              design that had gone before it. There was an
                              opposition to the decorative arts, which included
                              Art Nouveau, Neoclassical, and Victorian styles.
                            </p>
                          </div>
                        </div>
                        <a href="#" class="btn-theme">
                          LEARN MORE
                        </a>
                      </div>
                    </div>
                    <div class="col-lg-6 col-xl-5 offset-xl-1">
                      <div class="about-thumb">
                        <img
                          class="w-100"
                          src="/assets/img/about/3.jpg"
                          alt="Image-HasTech"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container pt--0">
            <div class="row">
              <div class="col-12">
                <div class="single-banner-image">
                  <a href="shop.html">
                    <img
                      class="w-100"
                      src="/assets/img/shop/banner/1.jpg"
                      alt="Image-HasTech"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="divider-area divider-offer-area bg-color-222">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="divider-style-wrap text-center">
                  <div class="divider-content">
                    <h4 class="sub-title">
                      Special <span>Offers</span> for Subscription
                    </h4>
                    <h4 class="title">GET INSTANT DISCOUNT FOR MEMBERSHIP</h4>
                    <p>
                      Subscribe our newsletter and get all latest news abot our
                      latest <br />
                      products, promotions, offers and discount
                    </p>
                    <div class="newsletter-content-wrap">
                      <div class="newsletter-form">
                        <form action="#">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Enter your email here"
                          />
                          <button class="btn-submit" type="button">
                            Subscribe
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonials />
      </div>
    );
  }
}

export default About3;

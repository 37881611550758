import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };

    render() {
        return (
            <>
                    <ul className=" nav navbar-nav">
                        <li className={window.location.pathname === "/" ? "active" : ""}>
                            <NavLink to={"/"}>Home</NavLink>
                        </li>
                        <li className={window.location.pathname === "/aboutus" ? "active" : ""}>
                            <NavLink to={"/aboutus"}>About us</NavLink>
                        </li>
                        <li className={window.location.pathname === "/contactus" ? "active" : ""}>
                            <NavLink to={"/contactus"}>Contact us</NavLink>
                        </li>
                        <li className={(window.location.pathname === "/news-grid" || window.location.pathname === "/news-listing" || window.location.pathname === "/news-masonary")  ? "active" : ""}>
                            <NavLink to={"#"}>News</NavLink>
                            <ul className="sub-menu v-sub-menu">
                                <li><NavLink to={"/news-grid"}>Grid</NavLink></li>
                                <li><NavLink to={"/news-listing"}>Listing</NavLink></li>
                                <li><NavLink to={"/news-masonary"}>Masonry</NavLink></li>
                            </ul>
                        </li>
                        <li className={(window.location.pathname === "/work-grid" || window.location.pathname === "/work-carousel" || window.location.pathname === "/work-masonary" || window.location.pathname === "/project-detail")  ? "active" : ""}> 
                            <NavLink to={"#"}>Works</NavLink>
                            <ul className="sub-menu v-sub-menu">
                                <li><NavLink to={"/work-grid"}>Grid</NavLink></li>
                                <li><NavLink to={"/work-masonary"}>Masonry</NavLink></li>
                                <li><NavLink to={"/work-carousel"}>Carousel</NavLink></li>
                                <li><NavLink to={"/project-detail"}>Project Detail</NavLink></li>
                            </ul>
                        </li>
                    </ul>
            </>
        );
    };
};

export default Navigation;